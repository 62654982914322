import React, { useRef, useState } from 'react'
import { motion, useAnimation } from "framer-motion"
import Box from '@mui/system/Box'
import DropEffect from '../dropEffect/DropEffect'
import LeavesAnimationAbs from './LeavesAnimationAbs'

const defaultAnimation = {
    scale: [ 0, 1, 1, 0 ],
    width: [ null, "50vw", "50vw", "50vw" ],
    rotateZ: [ null, null, 90, 90 ],
    transitionEnd: {
        display: "none",
    },
}

// const defaultTransition = {
//     duration: 10,
//     times: [ 0, 0.1, 0.1, 1 ],
// }

// const configAnimate = {
//     animate1: {
//         width: "20%",
//         rotateZ: [ null, null, 90, 90 ],
//         scale: [ 0, 1, 1, 0.5 ],
//         transitionEnd: {
//             // display: "none",
//         },
//         transition1: {
//             delay: 2, duration: 10,
//             times: [ 0, 0.8, 0.9, 1 ],
//             type: "spring",
//             damping: 1,
//             stiffness: 10,
//         }
//     },
//     defaultAnimate: {
//         scale: [ 0, 1, 1, 0 ],
//         width: [ null, "50vw", "50vw", "50vw" ],
//         rotateZ: [ null, null, 90, 90 ],
//         transitionEnd: {
//             display: "none",
//         },
//         transition: {
//             duration: 10,
//             times: [ 0, 0.1, 0.1, 1 ],
//         }
//     }
// }

const styleWrap1 = {
    position: "absolute",
    top: "80px",
    left: "0%",
    scale: 0,
}

// const animate = {
//     width: "20%",
//     rotateZ: [ null, null, 90, 90 ],
//     scale: [ 0, 1, 1, 0.5 ],
//     transitionEnd: {
//         // display: "none",
//     }
// }

// const transition = {
//     delay: 2, duration: 10,
//     times: [ 0, 0.8, 0.9, 1 ],
//     type: "spring",
//     damping: 1,
//     stiffness: 10,
// }

const LeavesInDropAnimation = ({
    // styleWrap = {
    //     top: "0px",
    //     left: "0px",
    //     maxWith: "320px"
    // },
    styleWrap = { ...styleWrap1 },
    // animate1 = { ...animate },
    animate = { ...defaultAnimation },
    // transition = { ...defaultTransition },
    // transition1 = { ...transition },
    name = ""
}) => {
    // console.log(name, styleWrap, " ============     LeavesInDropAnimation start ===================================")

    const [ runAnimation, setRunAnimation ] = useState(false)
    const refAlreadyFirstViewed = useRef(false)
    // const refCount = useRef(0)
    // const refToggle = useRef(0)
    const ctlAnim = useAnimation()
    // console.log(name, " ============     LeavesInDropAnimation end ===================================")

    return (
        <Box
            component={motion.div}
            className="LeavesInDropAnimation"
            layout
            initial={false}
            animate={ctlAnim}
            // transition={transition1}
            // whileInView={{
            //     ...animate,
            //     transition: { ...transition }
            // }}
            viewport={{ once: true }}
            onViewportEnter={async entry => {
                // console.log('%c viewportEnter alreadyViewed, Count ', 'color: red', name, refAlreadyFirstViewed.current, refCount.current)
                // console.log('%c viewportEnter count ', 'color: red', name, refCount.current)
                // console.log('%c viewportEnter toggle ', 'color: red', name, refToggle.current)
                // console.log('%c waved viewportEnter alreadyViewed ', 'color: red', name, refAlreadyFirstViewed.current)

                if (refAlreadyFirstViewed.current) {
                    // console.log('%c viewportEnter ctlAnim.start ', 'color: red', name)
                    setRunAnimation(true)
                    // seqAnimStop()
                    // // if (refRenderCount.current > 2) { 
                    // //   }
                    // seqAppear()
                    await ctlAnim.start(animate)
                    setRunAnimation(false)
                } else {
                    refAlreadyFirstViewed.current = true
                }
                // refCount.current += 1
                // refToggle.current = !refToggle.current
            }}
            onViewportLeave={entry => {
                // console.log("%c waved viewportLeave alreadyViewed refRenderCount ", 'color: red', name, refAlreadyFirstViewed.current, refRenderCount.current)
                // console.log('%c viewportLeave count ', 'color: red', name, refCount.current)
                // console.log("%c waved viewportLeave alreadyViewed ", 'color: red', name, refAlreadyFirstViewed.current)
                // seqAnimStop()
                // if (refRenderCount.current > 2) seqDisappear()
                if (refAlreadyFirstViewed.current) {
                    // seqAnimStop()
                    // seqDisappear()
                    ctlAnim.stop()
                } else {
                    refAlreadyFirstViewed.current = true
                }

            }}
            style={{
                maxWith: "100%",
                ...styleWrap
            }}
        >
            <DropEffect styleDrop={{ width: "100%" }}            >
                <LeavesAnimationAbs numberOfLeaves={10} runAnimation={runAnimation} />
            </DropEffect>
        </Box>
    )
}


const MemoizedLeavesInDropAnimation = React.memo(LeavesInDropAnimation,
    (prevProps, nextProps) => {
        // Perform a deep comparison of the animationKey prop
        const areAnimateEqual = JSON.stringify(prevProps.animate) === JSON.stringify(nextProps.animate);
        const areStyleWrapEqual = JSON.stringify(prevProps.styleWrap) === JSON.stringify(nextProps.styleWrap);
        // console.log("MemoizedLeavesInDropAnimation areStyleWrapEqual", areStyleWrapEqual)
        // Perform a shallow comparison of the rest of the props
        const areOtherPropsEqual = Object.keys(prevProps).every(key => {
            // if (key !== 'stylesTop' || 'stylesRight' || 'stylesBottom' || 'stylesLeft' || "children") return true;
            if (key !== "children") return true;
            return prevProps[ key ] === nextProps[ key ];
        });
        // console.log("MemoizedBowList areOtherPropsEqual", areOtherPropsEqual)

        // Only update the component if any of the props have changed
        return areAnimateEqual && areStyleWrapEqual && areOtherPropsEqual;
    }
);


export default MemoizedLeavesInDropAnimation